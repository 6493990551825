<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="close"
  >
    <template slot="title">
      Schedule Form
    </template>
    <template slot="body">
      <div class="md-layout">
        <div class="md-layout-item">
          <ExamsSelect
            v-model="form.placement_exam_id"
          />

          <form-date
            v-model="form.finish_date"
            label="Finish Date"
            :min-date="new Date()"
          />

          <md-switch v-model="by_start_date">
            Add Students by Start Date
          </md-switch>

          <StudentsAutocomplete
            v-if="!by_start_date"
            ref="autocomp"
            @change="onSelectStudent"
          />

          <form-date
            v-if="by_start_date"
            v-model="form.start_from"
            label="Starting From"
          />
          <form-date
            v-if="by_start_date"
            v-model="form.start_to"
            label="Starting To"
          />
        </div>
      </div>

      <div
        v-if="form.students.length"
        class="md-layout"
      >
        <div class="md-layout-item">
          <md-table>
            <md-table-row>
              <md-table-head>Student</md-table-head>
              <md-table-head style="width: 1%">
                Actions
              </md-table-head>
            </md-table-row>
            <md-table-row
              v-for="(st, index) in form.students"
              :key="st.student_id"
            >
              <md-table-cell>{{ st.name }}</md-table-cell>
              <md-table-cell>
                <md-button
                  class="md-danger md-simple md-just-icon"
                  @click="removeStudent(index)"
                >
                  <md-icon>
                    close
                  </md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </div>
    </template>
    <template slot="footer">
      <md-button
        class="md-primary pull-right"
        @click="saveForm"
      >
        <md-icon class="mr-3">
          save
        </md-icon>
        Save
      </md-button>
    </template>
  </vue-modal>
</template>

<script>
import {
  FormDate,
} from '@/components/Inputs';
import {
  ExamsSelect,
  StudentsAutocomplete,
} from '@/components/Inputs/selects';

export default {
  components: {
    FormDate,
    StudentsAutocomplete,
    ExamsSelect,
  },
  data() {
    return {
      form: {
        finish_date: null,
        start_from: null,
        start_to: null,
        placement_exam_id: null,
        students: [],
      },
      students: [],
      exams: [],
      selStudent: null,
      by_start_date: false,
    };
  },
  mounted() {
    this.getExams();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    saveForm() {
      if (this.by_start_date) {
        if (!this.form.start_from) {
          this.fireError('Select the Start Date FROM');
          return;
        }
        if (!this.form.start_to) {
          this.fireError('Select the Start Date TO');
          return;
        }
      } else if (this.form.students.length === 0) {
        this.fireError('You have to add some student');
        return;
      }
      if (!this.form.finish_date) {
        this.fireError('Select a finish date');
        return;
      }
      if (!this.form.placement_exam_id) {
        this.fireError('Select an exam');
        return;
      }

      this.request(this.$API.PLACEMENT_SCHEDULES, 'post', this.form, (res) => {
        this.fireSuccess(res);
        this.$emit('onSave');
        this.$refs.modal.close();
      });
    },
    getStudents(search) {
      this.request(this.$API.STUDENTS, 'get', {
        search,
      }, ({ data }) => {
        this.students = data.map((x) => {
          const aux = x;
          aux.name = `${x.student_number} - ${x.first_name} ${x.last_name}`;
          return aux;
        });
      });
    },
    getExams() {
      this.request(this.$API.PLACEMENT_EXAMS, 'get', {
        per_page: 1000,
      }, ({ data }) => {
        this.exams = data.data.map((x) => ({
          id: x.placement_exam_id,
          name: x.title,
        }));
      });
    },
    onSelectStudent(student) {
      if (this.form.students.filter((x) => x.student_id === student.student_id).length === 0) {
        this.form.students.push(student);
      } else {
        this.fireError('Student is already in the list');
      }
      this.$refs.autocomp.reset();
    },
    removeStudent(index) {
      this.form.students.splice(index, 1);
    },
  },
};
</script>
