<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="newSchedule"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Schedule</md-tooltip>
            </md-button>

            <md-button
              class="md-just-icon md-round md-info pull-right"
              @click="onSendAllEmail"
            >
              <md-icon>email</md-icon>
              <md-tooltip>Send Email to all waiting recipients</md-tooltip>
            </md-button>

            <div
              class="d-inline-block pull-right"
            >
              <md-switch
                v-model="filters.answered"
                @change="initTable"
              >
                Show Answered
              </md-switch>
            </div>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass: 'table',
              }"
              @onDelete="onDelete"
              @changed="getSchedules"
              @onSendEmail="onSendEmail"
              @goToProfile="goToProfile"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <schedules-form
      v-if="showForm"
      @close="closeForm"
      @onSave="initTable"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import SchedulesForm from './SchedulesForm.vue';

export default {
  components: {
    VueTable,
    SchedulesForm,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'student.student_number',
            mask: 'student number',
            sortable: true,
          },
          {
            title: 'student.first_name',
            mask: 'given name',
            sortable: true,
          },
          {
            title: 'student.last_name',
            mask: 'surname',
            sortable: true,
          },
          {
            title: 'finish_date',
            mask: 'exam expiration date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'student.programme.start_date',
            mask: 'course start date',
            dateFormat: true,
          },
          {
            title: 'is_finished',
            mask: 'answered',
            sortable: true,
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
          {
            title: 'email_sended',
            mask: 'Email sent',
            sortable: true,
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
        ],
        actions: [
          {
            buttonClass: 'md-primary',
            tooltip: 'Go To Profile',
            callback: 'goToProfile',
            icon: 'people',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Send Email',
            callback: 'onSendEmail',
            icon: 'email',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Schedule',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      showForm: false,
      filters: {
        answered: false,
      },
    };
  },
  mounted() {
    this.initTable();
  },
  methods: {
    getSchedules(params) {
      this.request(this.$API.PLACEMENT_SCHEDULES.paginableUrl(params), 'get', this.filters, ({ data }) => {
        this.vTable.values = data;
        this.vTable.values.data = this.vTable.values.data.map((x) => {
          const aux = x;
          // eslint-disable-next-line prefer-destructuring
          x.student.programme = x.student.programmes[0];

          return aux;
        });
      });
    },
    onDelete(item) {
      this.request(this.$API.PLACEMENT_SCHEDULES + item.placement_scheduled_id, 'delete', null, () => {
        this.initTable();
        this.fireSuccess('Schedule deleted');
      });
    },
    initTable() {
      this.$refs.vtable.init();
    },
    newSchedule() {
      this.showForm = true;
    },
    closeForm() {
      this.showForm = false;
    },
    onSendEmail(item) {
      this.fireConfirm('You are about to send an email', 'Are you sure?').then(() => {
        this.request(`${this.$API.PLACEMENT_SCHEDULES + item.placement_scheduled_id}/send`, 'post', null, (res) => {
          this.initTable();
          this.fireSuccess(res);
        });
      });
    },
    onSendAllEmail() {
      this.fireConfirm('You are about to send several emails', 'Are you sure?').then(() => {
        this.request(`${this.$API.PLACEMENT_SCHEDULES}send_all`, 'post', null, () => {});
        this.fireSuccess('Emails put in queue to send, thanks!');
      });
    },
    goToProfile(item, isMiddleClick) {
      this.openLink({ name: 'StudentProfile', params: { id: item.student_id } }, isMiddleClick);
    },
  },
};
</script>

<style scoped></style>
